<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="6" xl="4">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h1>登入系統</h1>
                  <p class="text-muted">輸入帳號與密碼登入系統</p>
                  <CInput placeholder="帳號" autocomplete="username email" v-model="username">
                    <template #prepend-content><CIcon name="cil-user" /></template>
                  </CInput>
                  <CInput placeholder="密碼" type="password" autocomplete="curent-password" v-model="password">
                    <template #prepend-content><CIcon name="cil-lock-locked" /></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton color="primary" class="px-4" @click="login()">登入</CButton>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <!-- <CButton color="link" class="px-0">Forgot password?</CButton>
                      <CButton color="link" class="d-lg-none">Register now!</CButton> -->
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
    <notifications />
  </div>
</template>

<script>
export default {
  name: "Login",
  data: () => ({
    username: "",
    password: "",
  }),
  methods: {
    login() {
      if (!this.username) {
        this.$notify({ type: "error", text: "請輸入帳號" });
        return;
      }
      if (!this.password) {
        this.$notify({ type: "error", text: "請輸入密碼" });
        return;
      }
      this.$http
        .post("/account/login", {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          if (response.data.success) {
            localStorage.setItem("authorization", response.data.result.token);
            this.$notify({ type: "success", text: "登入成功" });
            this.$router.push({ name: "首頁" });
          } else {
            this.$notify({ type: "error", text: response.data.message });
          }
        });
    },
  },
};
</script>
<style lang="scss">
// Import Main styles for this application
@import "@/assets/scss/style";
</style>